<template>
  <div class="box">
    <h2>{{ $t("common.teamStats") }}</h2>
    <XTable :x-content="content" :x-columns="columns">
      <template #cell[League]="{row,cell}">
        <router-link :to="`/team/${teamId}/${row.League_id}/${row.Season_id}`">{{ cell }}</router-link>
      </template>
    </XTable>
  </div>
</template>
  
<script>
import XTable from "@/components/tables/XTable.vue";
export default {
  components: {
    XTable,
  },
  props: ["content", "teamId"],
  data() {
    return {
      columns: [
      { t: "common.league", 
        name: "League",
      },
      { t: "common.season", 
        name: "Season" 
      },
      { t: "stats.pos", 
        name: "Position", 
        align: "center", 
        sortable: true,
      },
      { t: "stats.gp", 
        name: "GamesPlayed", 
        align: "center", 
        sortable: true,
        hideable: true,
        preferDesc: true, 
        compactable: true 
      },
      { t: "stats.w", 
        name: "Wins", 
        align: "center", 
        sortable: true,
        hideable: true,
        preferDesc: true, 
        compactable: true 
      },
      { t: "stats.d", 
        name: "Draws", 
        align: "center", 
        sortable: true,
        hideable: true,
        preferDesc: true, 
        compactable: true 
      },
      { t: "stats.l", 
        name: "Losses", 
        align: "center", 
        sortable: true,
        hideable: true,
        preferDesc: true, 
        compactable: true 
      },
      { t: "stats.gf", 
        name: "GoalsFor", 
        align: "center", 
        sortable: true,
        hideable: true,
        preferDesc: true, 
        compactable: true 
      },
      { t: "stats.ga", 
        name: "GoalsAgainst", 
        align: "center", 
        sortable: true,
        hideable: true,
        preferDesc: true, 
        compactable: true 
      },
      { t: "stats.gd", 
        name: "GoalDifference", 
        align: "center", 
        sortable: true,
        preferDesc: true, 
      },
      { 
        t: "stats.pts", 
        name: "Points", 
        align: "center", 
        sortable: true,
        preferDesc: true,
      },
    ],
    };
  },
};
</script>