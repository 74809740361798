<template>
  <Page404 v-if="page404" />
  <div class="team" v-else-if="teamData">
    <XNav
      :content="[
        { t: 'common.teams' },
        { name: teamName },
      ]"
    />
    <TeamBanner
      :team-name="teamName"
      :team-id="$route.params.id"
    />
    <TeamOverviewTable :content="teamData" :team-id="$route.params.id" />
  </div>
</template>

<script>
import axios from "axios";
import TeamBanner from "@/components/TeamBanner.vue";
import XNav from "@/components/XNav.vue";
import TeamOverviewTable from "@/components/tables/TeamOverviewTable.vue";
import Page404 from "@/views/404.vue";
export default {
  components: {
    TeamBanner,
    XNav,
    TeamOverviewTable,
    Page404,
  },
  data(){
    return{
      teamData: null,
      teamName: null,
      page404: false,
    }
  },
  created(){
    this.loadData();
  },
  methods: {
    loadData(){
      axios.get(`${this.$apiUrl}/teamOverview/${this.$route.params.id}`)
      .then((res) => {
        this.teamData = res.data;
        this.teamName = this.teamData[0].Team;
        this.$store.dispatch("pageLoad", false)
      })
      .catch((err) => {
        console.log(err);
        this.page404 = true;
        this.$store.dispatch("pageLoad", false)
      });
    }
  }
}
</script>
